import React, { useState } from 'react'

import { DateTimeInput } from '@te-digi/styleguide'

const DateTimeInputErrorExample = () => {
  const [value, setValue] = useState({ date: '', time: '' })

  return (
    <DateTimeInput
      dateError="Date Error"
      dateSubLabel="pp.kk.vvvv"
      timeSubLabel="tt:mm"
      label="Valitse päivämäärä ja aika"
      onChange={e => setValue(e)}
      timeError="Time Error"
      value={value}
    />
  )
}

export { DateTimeInputErrorExample }
