import React from 'react'

import {
  DateTimeInput,
  Divider,
  FormGroup,
  FormIndent,
  Inline,
  Label
} from '@te-digi/styleguide'

const DateTimeInputMultipleExample = () => (
  <FormGroup
    ariaLabelledby="input-group-example-label"
    noMargin
  >
    <Label
      as="p"
      id="input-group-example-label"
      subLabel="Merkitse päivämäärät muodossa pp.kk.vvvv ja kellonajat muodossa tt:mm"
    >
      Ryhmän yhteinen label
    </Label>

    <FormIndent>
      <FormGroup>
        <Inline gap="xl">
          <DateTimeInput onChange={undefined} />
        </Inline>
        <Divider />
      </FormGroup>
      <FormGroup>
        <Inline gap="xl">
          <DateTimeInput onChange={undefined} />
        </Inline>
      </FormGroup>
    </FormIndent>
  </FormGroup>
)

export { DateTimeInputMultipleExample }
