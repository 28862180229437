import React from 'react'

import { DateTimeInput, List, ListItem, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Color } from '../../components/Color'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { DateTimeInputExample } from '../../examples/react/DateTimeInputExample'
import { DateTimeInputErrorExample } from '../../examples/react/DateTimeInputErrorExample'
import { DateTimeInputMultipleExample } from '../../examples/react/DateTimeInputMultipleExample'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Link } from '../../components/Link'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="DateTimeInput"
    components={[{ component: DateTimeInput }]}
    status={[{ type: 'accessible', version: '16.3.0' }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          <Code>DateTimeInput</Code> on yhdistelmä{' '}
          <Code>
            <Link page="DateInput">DateInput</Link>
          </Code>{' '}
          ja{' '}
          <Code>
            <Link page="TimeInput">TimeInput</Link>
          </Code>{' '}
          -komponenteista.
        </ListItem>
        <ListItem>
          Osa propeista passataan <Code>DateInput</Code>ille, osa{' '}
          <Code>TimeInput</Code>ille, tarkempi dokumentaatio löytyy{' '}
          <a href="#api">API-listauksesta</a>. Käyttöesimerkit löytyvät
          komponenttien dokumentaatiosivuilta.
        </ListItem>
        <ListItem>
          Päivämäärän formaatin ohjeistus sijoitetaan <Code>dateSubLabel</Code>
          iin ja kellonajan formaatin ohjeistus sijoitetaan{' '}
          <Code>timeSubLabel</Code>iin
        </ListItem>
      </List>
    </Section>
    <Section>
      <Playground
        enableOverflow
        example={DateTimeInputExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Virheviestit">
      <List variant="unordered">
        <ListItem>
          Komponentille annettu arvo tulee validoida ja passata sen mukainen
          virheviesti <Code>dateError</Code>- ja <Code>timeError</Code>
          -propeille. <Code>DateTimeInput</Code> ei tee mitään validointia.
        </ListItem>
        <ListItem>
          Virheviestiä käyttäessä inputin reunaviiva näytetään{' '}
          <Color color="danger" />
          -värillä.
        </ListItem>
      </List>
      <Playground
        enableOverflow
        example={DateTimeInputErrorExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Monta kenttää, yhteinen subLabel">
      <Paragraph>
        Toiston välttämiseksi (sekä visuaalisesti että ruudunlukijalla luettuna)
        esitetään ohjeistus ryhmän yhteisessä subLabelissa.
      </Paragraph>
      <Playground
        enableOverflow
        example={DateTimeInputMultipleExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Disabloitu">
      <Paragraph>
        Ei-muokattava tieto tulisi yleensä esittää{' '}
        <Code>
          <Link page="Detail" />
        </Code>
        -komponentilla. Jos tieto on väliaikaisesti disabloitu (esim. lomakkeen
        muut valinnat vaikuttavat siihen, tarvitseeko tietoa syöttää), silloin
        voidaan käyttää disabloitua kenttää. Ruudunlukijalla disabled-kenttä
        hypätään yli.
      </Paragraph>
      <Playground
        enableOverflow
        WrapperComponent={FormLayoutWrapper}
      >
        <DateTimeInput
          dateSubLabel="pp.kk.vvvv"
          timeSubLabel="tt:mm"
          disabled
        />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <List variant="unordered">
        <ListItem>
          Jokaisella syötteellä pitää olla label, joka kuvaa, mitä ollaan
          valitsemassa.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
