import React, { useState } from 'react'

import { DateTimeInput, FormText } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const DateTimeInputExample = () => {
  const [value, setValue] = useState({ date: '', time: '' })

  return (
    <>
      <DateTimeInput
        dateSubLabel="pp.kk.vvvv"
        timeSubLabel="tt:mm"
        label="Valitse päivämäärä ja aika"
        onChange={e => setValue(e)}
        onDateSelection={val => console.log('onDateSelection:', val)}
        onTimeSelection={val => console.log('onTimeSelection:', val)}
        required
        subLabel="Sub Label"
        value={value}
      />
      <FormText>
        Arvo: <Code>{JSON.stringify(value)}</Code>
      </FormText>
    </>
  )
}

export { DateTimeInputExample }
