import React from 'react'
import { Text } from '@te-digi/styleguide'
import tokens from '@te-digi/styleguide-tokens'
import styled from 'styled-components'

import { Code } from './Code'

type ColorType = keyof typeof tokens.color

interface ColorProps {
  color: ColorType
}

const StyledBullet = styled.span<{ $color: ColorType }>`
  background-color: ${props => tokens.color[props.$color]};
  border: 1px solid ${tokens.color['neutral-4']};
  border-radius: 50%;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: ${tokens.space.xxs};
`

const Color = ({ color }: ColorProps) => (
  <Text whiteSpace="nowrap">
    <StyledBullet $color={color} />
    <Code>{color}</Code>
  </Text>
)

export { Color }
